<template>
  <div class="p-4">
    <div class="text-gray-800">
      <p>Amazonと楽天ブックスのレビューが同時に見れることで、技術書選びをサポートします。</p>
      <p>良い技術書を見つけて学びましょう！</p>
    </div>
    <div>
      <svg class="h-32 w-32 text-gray-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
      </svg>
    </div>
    <div class="underline text-center my-5">
      <router-link to="/">
        トップページへ
      </router-link>
    </div>
  </div>
</template>
